<!--会员管理-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <label>场景：</label>
                <el-input placeholder="搜索会员名称" v-model="select.search" size="small" style="width:300px;margin-right:auto" @change="curr=1;getList()" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff" @click="curr=1;getList()"></el-button>
                </el-input>
                <el-button type="primary" block size="small" @click="edit()">添加</el-button>
                <el-button-group style="margin:0 5px;" v-if="isSwitch">
                    <el-button type="success" size="small" @click="setupSwitch(1)" icon="el-icon-top">上架</el-button>
                    <el-button type="success" size="small" @click="setupSwitch(0)" icon="el-icon-bottom">下架</el-button>
                </el-button-group>
            </div>
        </div>

        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' @selection-change="handleSelectionChange" height="calc( 100% - 41px )">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column prop="product_name" label="商品名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="price" label="价格" width="80" show-overflow-tooltip></el-table-column>
				<el-table-column prop="years" label="时间(天)" width="130" show-overflow-tooltip></el-table-column>
				<el-table-column prop="state_name" label="上架" width="80" align="center" show-overflow-tooltip>
				  <template slot-scope="scope">
					  <el-switch v-model="scope.row.is_show" :active-value="1"  :inactive-value="0" @change="setupSwitch(scope.row,'show')"></el-switch>
				  </template>
			  </el-table-column>
              <el-table-column label="操作" align="center" fixed="right" width="320">
                    <template slot-scope="scope">
                        <el-button type="success" plain size="mini" @click="edit(scope.row,'show')">查看</el-button>
                        <el-button type="primary" plain size="mini" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {cateListApi,shopTypeListApi,cateListDelApi,shopListSortlApi,shopActiveSetApi,cateShowAllApi,cateShowApi,ysalesshow}  from '@/api/store.js';
	import {memberLevelListApi}  from '@/api/config.js';
    export default {
        data() {
            return {
                select:{},
                selectData:[],//下拉选择列表
                list:[], //商品列表
                previewList:[], //图片预览列表

                title:'添加商品',
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
                switchArrayId:'',

                dialogFormVisible:false,//活动设置弹窗
                isSwitch:false, //是否展示上下架
				// memberlist:{},
				
				formData:{
				  num_limit:0,
				  vip_limit:0
				},
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init: function(){
				// this.membergetList()
                this.getList();
                this.getTypeList();
				// 会员等级
            },
            //商品列表
            getList: function(){
                cateListApi({
                    curr:this.curr,
                    row:this.row,
                    ...this.select
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                    // response.list.forEach(item => {
                    //     this.previewList.push(this.domain + item.image);
                    // });
                })
            },
            //获取商品分类
            getTypeList: function(){
                shopTypeListApi({
                    curr:1,
                    row:99999
                }).then(response=>{
					console.log("res",response)
                    this.selectData['商品分类'] = response;
                })
				
            },
            /**
             * 活动设置
            */
            setupActive: function(row){
                this.dialogFormVisible = true;
                this.formData = row;
                this.$set(this.formData,'seckill',false);
                this.$set(this.formData,'group',false);
                this.$set(this.formData,'is_best',false)
				ysalesshow({
					 id:row.id
				}).then(response=>{
					if(response.pre!=null){
						this.$set(this.formData,'is_pre',true)				
						this.$set(this.formData,'vip_limit',response.pre.vip_limit)
						this.$set(this.formData,'pay_first_time',[response.pre.ext_pay_first_from_time,response.pre.ext_pay_first_to_time])
					}else{
						this.$set(this.formData,'is_pre',false)
					}
				})
				
            },

            //编辑商品
            edit: function(param = {},_state){
                //判断添加商品or编辑商品
                if(typeof param.id == 'undefined'){
                    this.title = '添加';
                    this.isShow = false;
                }else if(typeof param.id && _state){
                    this.title = '商品详情';
                    this.isShow = true;
                }else {
                    this.title = '编辑';
                    this.isShow = false;
                }
                //跳转至添加or编辑页面
                this.$router.push({path:'/cateMemberAdd',query:{id:param.id,title:this.title,isShow:JSON.stringify(this.isShow)}});
            },

            //删除商品
            deletes:function(id){
                this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    cateListDelApi({
                        id:id
                    }).then(()=>{
                        this.$message({
                            type: 'success',
                            message: '已删除!'
                        });
                        this.getList();
                    })
                }).catch(()=>{})
            },

            //设置上下架
            setupSwitch: function(row,state) {
                let switchApi = state ? cateShowApi : cateShowAllApi; //接口批量or单独
                let rows = state ? row.is_show  : row; //上架、下架
                let switchId = state ? row.id : this.switchArrayId; //选择上下架id
                switchApi({
                    id:switchId,
                    is_show:rows
                }).then(()=>{
                    this.$message.success('设置成功!');
                    this.getList();
                }).catch(()=>{
                    this.getList();
                })
            },

            //批量设置上架
            handleSelectionChange: function(val) {
                this.isSwitch = val.length != 0 ? true : false;
                this.switchArrayId =  val.map(item => item.id).toString()   
            },

            //活动设置提交
            submit: function(){
                shopActiveSetApi({
                    id:this.formData.id,
                    // is_seckill:this.formData.seckill == true ? 1 : 0,
                    // is_group:this.formData.group == true ? 1 : 0,
                    // seckill_time:this.formData.seckill_time,
                    // group_time:this.formData.group_time,
                    is_best:this.formData.best == true ? 1 : 0,
					is_pre:this.formData.is_pre == true ? 1 : 0,
					pay_first_time:this.formData.pay_first_time,
					// num_limit:this.formData.num_limit,
					vip_limit:this.formData.vip_limit					
                }).then(()=>{
                    this.dialogFormVisible = false;
                    this.getList();
                    this.$message({
                        type: 'success',
                        message: '活动设置成功!'
                    });
                })
            },
            //导出
            exportFun:function(){
                this.$confirm(`确定导出商品吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    let url =`/exportExcel/product?param={"cate_id":"${this.select.cate_id}","search":"${this.select.search}"}`;
                    location.href = this.domain + url;
                    loading.close();
                }).catch(()=>{
                    this.$message('已取消当前操作')
                })
            },
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
			
			//会员列表
			// membergetList: function(){
			//     memberLevelListApi({
			//     }).then(response=>{
			//         this.memberlist = response.list;
			//     })
			// },
        },
    }
</script>